<template>
  <main>
    <div class="position-relative">
      <div class="container space-top-3 space-bottom-2">
        <div class="w-md-80 w-lg-65 text-center mx-md-auto mb-9">
          <h1 class="h2 pricing-title">Find the Plan That's Right for You</h1>
          <p class="pricing-description">Whether you're an individual developer, startup or a large organization, FabriXAPI has a plan to help you grow.</p>
        </div>

        <div class="row position-relative justify-content-center z-index-2 mx-n2 mb-6">
          <!-- Starter -->
          <div class="col-sm-6 col-md-4 px-2 mb-6">
            <!-- Pricing -->
            <div class="card h-100">
              <!-- Header -->
              <div class="card-header text-center border-bottom">
                <span class="d-block h3">Starter</span>
                <span class="d-block small mb-2">Free starter features ideal for individuals and small teams</span>
                <span class="d-block mb-2">
                  <span class="font-size-4 text-dark font-weight-bold mr-n2">
                    <span>Free</span>
                  </span>
                </span>
                <a class="btn btn-primary btn-block" href="https://provider-portal.fabrixapi.com/" target="_blank">Start for Free</a>
              </div>
              <!-- End Header -->

              <!-- Body -->
              <div class="card-body">
                <span class="d-block small mb-3">Includes</span>

                <div class="mb-4">
                  <h6>Portal Access</h6>
                  <div class="media font-size-1 text-body mb-2">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      Public Access
                    </div>
                  </div>
                  <div class="media font-size-1 text-body mb-0">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      Password Protected
                    </div>
                  </div>
                </div>

                <div class="mb-4">
                  <h6>API Subscription Plan Limits</h6>
                  <div class="media font-size-1 text-body mb-2">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      3 Public Plans
                    </div>
                  </div>
                  <div class="media font-size-1 text-body mb-2">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      50 Unlisted Plans
                    </div>
                  </div>
                  <div class="media font-size-1 text-body mb-0">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      100K API Counts / API Plan
                    </div>
                  </div>
                </div>

                <div class="mb-4">
                  <h6>Developer Management</h6>
                  <div class="media font-size-1 text-body mb-2">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      100 Monthly Active Subscription
                    </div>
                  </div>
                  <div class="media font-size-1 text-body mb-0">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      100 Developer Leads Count
                    </div>
                  </div>
                </div>

                <div class="mb-4">
                  <h6>Billing & Monetization</h6>
                  <div class="media font-size-1 text-body mb-2">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      Standard Flow with Manual Approval Process (Offline Payment Collections)
                    </div>
                  </div>
                  <div class="media font-size-1 text-body mb-2">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      Stripe Connect—<span class="font-weight-bold">20% Platform Charge</span>
                    </div>
                  </div>
                  <div class="media font-size-1 text-body mb-0">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      Bring Your Own Stripe (Coming Soon)
                    </div>
                  </div>
                </div>

                <div class="mb-4">
                  <h6>Portal Access Control</h6>
                  <div class="media font-size-1 text-body mb-2">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      FabriXAPI ID
                    </div>
                  </div>
                </div>

                <div class="mb-4">
                  <h6>Portal Usage</h6>
                  <div class="media font-size-1 text-body mb-2">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      100K API Volume per month
                    </div>
                  </div>
                </div>

                <div class="mb-4">
                  <h6>Support</h6>
                  <div class="media font-size-1 text-body mb-2">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      Email Support
                    </div>
                  </div>
                </div>

              </div>
              <!-- End Body -->
            </div>
          <!-- End Pricing -->
          </div>

          <!-- Essential -->
          <div class="col-sm-6 col-md-4 px-2 mb-6">
            <!-- Pricing -->
            <div class="card h-100">
              <!-- Header -->
              <div class="card-header text-center border-bottom">
                <span class="d-block h3">Essential</span>
                <span class="d-block small mb-2">Ideal for individuals who want increased productivity</span>
                <span class="d-block mb-2">
                  <span class="align-top">USD</span>
                  <span class="font-size-4 font-weight-bold mr-2">
                    <span>69</span>
                  </span>
                  <span class="font-size-1">/ month</span>
                </span>
                <a class="btn btn-primary btn-block" href="https://provider-portal.fabrixapi.com/" target="_blank">Register Now</a>
              </div>
              <!-- End Header -->

              <!-- Body -->
              <div class="card-body">
                <span class="d-block small mb-3">All Starter features, plus</span>

                <div class="mb-4">
                  <h6>API Subscription Plan Limits</h6>
                  <div class="media font-size-1 text-body mb-2">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      4 Public Plans
                    </div>
                  </div>
                  <div class="media font-size-1 text-body mb-2">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      250 Unlisted Plans
                    </div>
                  </div>
                  <div class="media font-size-1 text-body mb-0">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      500K API Counts / API Plan
                    </div>
                  </div>
                </div>

                <div class="mb-4">
                  <h6>Developer Management</h6>
                  <div class="media font-size-1 text-body mb-2">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      300 Monthly Active Subscription
                    </div>
                  </div>
                  <div class="media font-size-1 text-body mb-0">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      500 Developer Leads Count
                    </div>
                  </div>
                </div>

                <div class="mb-4">
                  <h6>Billing & Monetization</h6>
                  <div class="media font-size-1 text-body mb-0">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      Stripe Connect—<span class="font-weight-bold">12% Platform Charge</span>
                    </div>
                  </div>
                </div>

                <div class="mb-4">
                  <h6>Portal Usage</h6>
                  <div class="media font-size-1 text-body mb-2">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      500K API Volume per month
                    </div>
                  </div>
                </div>

              </div>
              <!-- End Body -->
            </div>
          <!-- End Pricing -->
          </div>

          <!-- Professional -->
          <div class="col-sm-6 col-md-4 px-2 mb-6">
            <!-- Pricing -->
            <div class="card bg-navy text-white h-100 shadow-primary-lg">
              <!-- Header -->
              <div class="card-header border-0 bg-navy text-white text-center">
                <span class="d-block text-white h3">Professional</span>
                <span class="d-block text-white small mb-2">Ideal for individuals who want increased productivity</span>
                <span class="d-block text-white mb-2">
                  <span class="align-top">USD</span>
                  <span class="font-size-4 font-weight-bold mr-2">
                    <span>299</span>
                  </span>
                  <span class="font-size-1">/ month</span>
                </span>
                <a class="btn btn-light text-secondary btn-block" href="https://provider-portal.fabrixapi.com/" target="_blank">Register Now</a>
              </div>
              <!-- End Header -->

              <div class="border-top opacity-xs" />

              <!-- Body -->
              <div class="card-body">
                <span class="d-block small mb-3 text-white">All Essential features, plus</span>

                <div class="mb-4">
                  <h6 class="text-white">API Subscription Plan Limits</h6>
                  <div class="media font-size-1 text-body mb-2">
                    <i class="fas fa-check-circle text-white mt-1 mr-2" />
                    <div class="media-body text-white">
                      5 Public Plans
                    </div>
                  </div>
                  <div class="media font-size-1 text-body mb-2">
                    <i class="fas fa-check-circle text-white mt-1 mr-2" />
                    <div class="media-body text-white">
                      500 Unlisted Plans
                    </div>
                  </div>
                  <div class="media font-size-1 text-body mb-0">
                    <i class="fas fa-check-circle text-white mt-1 mr-2" />
                    <div class="media-body text-white">
                      1M API Counts / API Plan
                    </div>
                  </div>
                </div>

                <div class="mb-4">
                  <h6 class="text-white">Developer Management</h6>
                  <div class="media font-size-1 text-body mb-2">
                    <i class="fas fa-check-circle text-white mt-1 mr-2" />
                    <div class="media-body text-white">
                      800 Monthly Active Subscription
                    </div>
                  </div>
                  <div class="media font-size-1 text-body mb-0">
                    <i class="fas fa-check-circle text-white mt-1 mr-2" />
                    <div class="media-body text-white">
                      1000 Developer Leads Count
                    </div>
                  </div>
                </div>

                <div class="mb-4">
                  <h6 class="text-white">Billing & Monetization</h6>
                  <div class="media font-size-1 text-body mb-0">
                    <i class="fas fa-check-circle text-white mt-1 mr-2" />
                    <div class="media-body text-white">
                      Stripe Connect—<span class="font-weight-bold">8% Platform Charge</span>
                    </div>
                  </div>
                </div>

                <div class="mb-4">
                  <h6 class="text-white">Portal Usage</h6>
                  <div class="media font-size-1 text-body mb-0">
                    <i class="fas fa-check-circle text-white mt-1 mr-2" />
                    <div class="media-body text-white">
                      1M API Volume per month
                    </div>
                  </div>
                </div>

                <div class="mb-4">
                  <h6 class="text-white">Support</h6>
                  <div class="media font-size-1 text-body mb-0">
                    <i class="fas fa-check-circle text-white mt-1 mr-2" />
                    <div class="media-body text-white">
                      SLA
                    </div>
                  </div>
                </div>

              </div>
              <!-- End Body -->
            </div>
          <!-- End Pricing -->
          </div>

          <!-- Business -->
          <div class="col-sm-6 col-md-4 px-2 mb-6 mb-md-0">
            <!-- Pricing -->
            <div class="card h-100">
              <!-- Header -->
              <div class="card-header text-center border-bottom">
                <span class="d-block h3">Business</span>
                <span class="d-block small mb-2">Ideal for teams at high-growth companies</span>
                <span class="d-block mb-2">
                  <span class="font-size-4 font-weight-bold mr-2">
                    <span>Custom</span>
                  </span>
                </span>
                <a class="btn btn-primary btn-block" href="javascript:;" data-toggle="modal" data-target="#oahResourcesModal">Contact Us</a>
              </div>
              <!-- End Header -->

              <!-- Body -->
              <div class="card-body">
                <span class="d-block small mb-3">All Professional features, plus</span>

                <div class="mb-4">
                  <h6>API Subscription Plan Limits</h6>
                  <div class="media font-size-1 text-body mb-2">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      5 Public Plans
                    </div>
                  </div>
                  <div class="media font-size-1 text-body mb-2">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      2000 Unlisted Plans
                    </div>
                  </div>
                  <div class="media font-size-1 text-body mb-0">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      5M API Counts / API Plan
                    </div>
                  </div>
                </div>

                <div class="mb-4">
                  <h6>Developer Management</h6>
                  <div class="media font-size-1 text-body mb-2">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      Custom Number of Monthly Active Subscription
                    </div>
                  </div>
                  <div class="media font-size-1 text-body mb-0">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      5000 Developer Leads Count
                    </div>
                  </div>
                </div>

                <div class="mb-4">
                  <h6>Billing & Monetization</h6>
                  <div class="media font-size-1 text-body mb-0">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      Stripe Connect—<span class="font-weight-bold">Custom Amount of Platform Charge</span>
                    </div>
                  </div>
                </div>

                <div class="mb-4">
                  <h6>Portal Usage</h6>
                  <div class="media font-size-1 text-body mb-2">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      5M API Volume per month
                    </div>
                  </div>
                  <div class="media font-size-1 text-body mb-0">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      Custom Domain
                    </div>
                  </div>
                </div>

                <div class="mb-4">
                  <h6>Support</h6>
                  <div class="media font-size-1 text-body mb-0">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      Technical Account Manager
                    </div>
                  </div>
                </div>

              </div>
              <!-- End Body -->
            </div>
          <!-- End Pricing -->
          </div>

          <!-- Enterprise -->
          <div class="col-sm-6 col-md-4 px-2 mb-6 mb-md-0">
            <!-- Pricing -->
            <div class="card h-100">
              <!-- Header -->
              <div class="card-header text-center border-bottom">
                <span class="d-block h3">Enterprise</span>
                <span class="d-block small mb-2">Ideal for large organizations with advanced needs</span>
                <span class="d-block mb-2">
                  <span class="font-size-4 font-weight-bold mr-2">
                    <span>Custom</span>
                  </span>
                </span>
                <a class="btn btn-primary btn-block" href="javascript:;" data-toggle="modal" data-target="#oahResourcesModal">Contact Us</a>
              </div>
              <!-- End Header -->

              <!-- Body -->
              <div class="card-body">
                <span class="d-block small mb-3">All Business features, plus</span>

                <div class="mb-4">
                  <h6>API Subscription Plan Limits</h6>
                  <div class="media font-size-1 text-body mb-2">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      Custom Number of Public Plans
                    </div>
                  </div>
                  <div class="media font-size-1 text-body mb-2">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      Custom Number of Unlisted Plans
                    </div>
                  </div>
                  <div class="media font-size-1 text-body mb-0">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      Custom Number of API Counts / API Plan
                    </div>
                  </div>
                </div>

                <div class="mb-4">
                  <h6>Developer Management</h6>
                  <div class="media font-size-1 text-body mb-2">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      Custom Number of Monthly Active Subscription
                    </div>
                  </div>
                  <div class="media font-size-1 text-body mb-0">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      Custom Number of Developer Leads Count
                    </div>
                  </div>
                </div>

                <div class="mb-4">
                  <h6>Portal Access Control</h6>
                  <div class="media font-size-1 text-body mb-2">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      Custom FabriXAPI ID
                    </div>
                  </div>
                  <div class="media font-size-1 text-body mb-0">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      Bring Your Own SSO
                    </div>
                  </div>
                </div>

                <div class="mb-4">
                  <h6>Billing & Monetization</h6>
                  <div class="media font-size-1 text-body mb-0">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      Stripe Connect—<span class="font-weight-bold">Custom Amount of Platform Charge</span>
                    </div>
                  </div>
                </div>

                <div class="mb-4">
                  <h6>Portal Usage</h6>
                  <div class="media font-size-1 text-body mb-2">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      Custom Number of API Volume per month
                    </div>
                  </div>
                </div>

                <div class="mb-4">
                  <h6>Support</h6>
                  <div class="media font-size-1 text-body mb-0">
                    <i class="fas fa-check-circle text-primary mt-1 mr-2" />
                    <div class="media-body">
                      Custom Professional Services
                    </div>
                  </div>
                </div>

              </div>
              <!-- End Body -->
            </div>
          <!-- End Pricing -->
          </div>
        </div>
      </div>

      <!-- FAQ Section -->
      <div class="bg-light">
        <div class="container space-2">
          <div class="w-md-80 w-lg-65 text-center mx-md-auto mb-9">
            <h1 class="h2 pricing-title">Frequently Asked Questions</h1>
          </div>

          <div class="w-lg-85 mx-lg-auto">
            <div class="card mb-5">
              <div class="card-body">
                <h4>What happens if I exceed my usage limits?</h4>
                <p>As a developer, you will not be able to make additional API calls to your API Providers if the subscription plan limit is reached. You will need to reach out your API providers for details or you can subscribe a higher tier plan from the API Provider.</p>
                <p>As an API Provider, if your API volume per month exceeds the usage limit of your current Portal Plan, you will not be able to make any changes on your Portal setting until you have settled your overage fees. The platform provides graceful handling for the existing developer subscriptions by best effort.</p>
              </div>
            </div>

            <div class="card mb-5">
              <div class="card-body">
                <h4>Is my data safe?</h4>
                <p>We take security and data protection as top priority. We obtain industry-accepted certifications and comply with current industry standards and regulations.</p>
                <p>We are ISO/IEC 27001:2013 Certified in the provision of API (Application Programming Interface) platform service, consisting of:</p>
                <ul>
                  <li>API Service Subscription Management</li>
                  <li>API Provider Onboarding and Offboarding</li>
                  <li>API Subscriber Onboarding and Offboarding</li>
                  <li>API Service Onboarding and Offboarding</li>
                  <li>API Platform Software Development</li>
                </ul>
                <p>Find out why big enterprises trust us (<a href="https://www.benovelty.com/trust" target="_blank">https://www.benovelty.com/trust</a>).</p>
              </div>
            </div>

            <div class="card mb-5">
              <div class="card-body">
                <h4>How will I get charged for my payments?</h4>
                <p>For Developer membership, you can join anytime for FREE until you want to subscribe a specific API plan from providers. We support credit card as payment methods.</p>
                <p>For the API Portal plan, you can start with our FREE starter plan initially and then talk to our sales team when you are ready to upgrade to more advanced plans. Please note that the charge is non-refundable by default. However, if there are any special situations, you can submit a refund request within 30 days of the payment date, and we will evaluate each case on its own merits.</p>
              </div>
            </div>

            <div class="card mb-5">
              <div class="card-body">
                <h4>Why the price of Business & Enterprise Plan are missing?</h4>
                <p>As per our experience, it comes to various customized options and integration for Business & Enterprise clients. Please talk to our consultants if you think the current standard plans are not sufficient for you and we can work with you on this together.</p>
              </div>
            </div>

            <div class="card mb-5">
              <div class="card-body">
                <h4>Can I upgrade and downgrade anytime?</h4>
                <p>Feel free to talk to our sales if you want to upgrade, downgrade or cancel your subscription renewal anytime.</p>
              </div>
            </div>

            <div class="card mb-5">
              <div class="card-body">
                <h4>Can FabriXAPI be used in on-premise setting?</h4>
                <p>FabriXAPI is a managed cloud API services platform. If you are looking for on-premise setup or enterprise profession API services. Please contact our API consultants in our team website (<a href="https://www.benovelty.com/trust" target="_blank">https://www.benovelty.com/trust</a>).</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End FAQ Section -->

    </div>

    <!-- Book a Demo -->
    <div class="container space-2">
      <div class="card border-0 shadow" :style="{ backgroundImage: `url(${require(`@/assets/svg/components/ben-hero-1.svg`)})`}">
        <div class="card-body text-center py-5">
          <h2>Want a Tour of FabriXAPI Portal?</h2>
          <p>Contact our team to book a demo and get started with your API Journey!</p>
          <router-link
            :to="{ path: `/contact`}"
            class="btn btn-primary mt-3"
          >Contact Us
          </router-link>
        </div>
      </div>
    </div>

    <!-- Hubspot Form - Portal Plan Upgrade Modal -->
    <div id="oahResourcesModal" data-keyboard="false" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="oahResourcesModalTitle" aria-hidden="true">
      <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="oahResourcesModal" class="modal-title" />
            <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
              <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
              </svg>
            </button>
          </div>
          <div class="modal-body text-center">
            <div class="w-md-90 mx-auto mb-6 form-submit">
              <form ref="upgradeForm" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Hubspot Form - beNovelty General Inquiry Modal -->

  </main>
</template>

<script>
import 'bootstrap'
export default {
  name: 'Pricing',
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
  },
  async mounted() {
    this.loadScriptUpgradeForm('https://js.hsforms.net/forms/v2.js', () => {
      const secondScript = document.createElement('script')
      secondScript.innerHTML = `
        hbspt.forms.create({
          portalId: "7091000",
          formId: "b20fb3bd-0aea-45b5-9493-c02301f45050"
        });
      `
      this.$refs.upgradeForm.appendChild(secondScript)
    })
  },
  methods: {
    loadScriptUpgradeForm(url, callback) {
      var script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = url
      script.onreadystatechange = callback
      script.onload = callback
      this.$refs.upgradeForm.appendChild(script)
    }
  },
  metaInfo() {
    return {
      title: 'Pricing',
      titleTemplate: '%s | FabriXAPI',
      link: [
        { rel: 'canonical', href: 'https://www.fabrixapi.com/pricing' }
      ],
      meta: [
        { name: 'description', content: 'An award-winning one-stop API platform services connecting digital businesses to embrace Open API Economy.' },
        { property: 'og:site_name', content: 'FabriXAPI' },
        { property: 'og:title', content: 'Pricing | FabriXAPI' },
        { property: 'og:description', content: 'An award-winning one-stop API platform services connecting digital businesses to embrace Open API Economy.' },
        { property: 'og:image', content: 'https://www.fabrixapi.com/assets/img/thumbnails/fabrixapi-thumbnail.jpg' },
        { property: 'og:url', content: 'https://www.fabrixapi.com/pricing' }
      ]
    }
  }
}
</script>

<style lang="scss">
</style>
